import React from "react";
import { CANVAS_SIZE } from "./constants";
import useSnake from "./useSnake";
import { setWinner } from "../../redux/slices/snakeSlice";
import { useAppDispatch } from "../../redux/hooks";

const Snake = ({
  foodLeft,
  setFoodLeft,
}: {
  foodLeft: number[];
  setFoodLeft: (x: any) => void;
}) => {
  const { moveSnake, canvasRef, startGame, playing } = useSnake({
    foodLeft,
    setFoodLeft,
  });
  const dispatch = useAppDispatch();
  return (
    <div role="button" tabIndex={0} onKeyDown={(e: any) => moveSnake(e)}>
      <canvas
        ref={canvasRef}
        width={`${CANVAS_SIZE[0]}px`}
        height={`${CANVAS_SIZE[1]}px`}
      />
      <div className="absolute bottom-12 right-8 flex text-sm">
        <button
          className="bottom-0 right-0 bg-[#FEA55F] py-2 px-3 rounded-lg z-10"
          onClick={startGame}
        >
          {playing ? "restart" : "start-game"}
        </button>
        <button
          onClick={() => dispatch(setWinner(true))}
          className="text-whiteText rounded-lg border border-whiteText py-2 px-3 mx-2 z-10"
        >
          skip
        </button>
      </div>
    </div>
  );
};

export default Snake;
