import React, { useState, useEffect } from "react";
import HeaderButton from "./HeaderButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeController, showMenu } from "../../redux/slices/controllerSlice";
import { useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import { svgs } from "../../utils/svgs";
import Menu from "../Menu/Menu";
import { setWinner } from "../../redux/slices/snakeSlice";
import useScreenSize from "../../utils/useScreenSize";

export default function Header() {
  const { menuHamburguesa } = svgs();
  const [page, setPage] = useState(0);
  const { width } = useScreenSize();
  const { winner } = useAppSelector((state) => state.snake);
  const { menu } = useAppSelector((state) => state.controller);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (width < 1280 && !winner) dispatch(setWinner(true));
  }, [width, dispatch, winner]);

  if (!menu)
    return (
      <div className="flex absolute z-10 w-full max-h-14 h-14 items-center justify-between border-b border-genBorder">
        <div className="flex h-full">
          <div className="flex items-center pl-6 max-w-xs w-72 h-full md:border-r md:border-genBorder">
            <h1 className="text-graytext md:hidden">diego cerda</h1>
            {winner && (
              <motion.h1
                key={"nameButton"}
                initial={{ opacity: 0.5, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.75 }}
                className="hidden text-graytext md:block"
              >
                diego cerda
              </motion.h1>
            )}
          </div>

          <div className="hidden h-full md:flex">
            <HeaderButton
              label="_hello"
              selected={page === 0 ? true : false}
              onClick={() => {
                if (window.location.pathname !== "/") navigate("/");
                dispatch(changeController("home"));
                setPage(0);
              }}
            />
            {winner && (
              <AnimatePresence>
                <HeaderButton
                  key={"aboutButton"}
                  animated={true}
                  label="_about-me"
                  selected={page === 1 ? true : false}
                  onClick={() => {
                    if (window.location.pathname !== "/") navigate("/");
                    dispatch(changeController("about"));
                    setPage(1);
                  }}
                />
                <HeaderButton
                  key={"projectsButton"}
                  animated={true}
                  duration={1.25}
                  label="_projects"
                  selected={page === 2 ? true : false}
                  onClick={() => {
                    if (window.location.pathname !== "/") navigate("/");
                    dispatch(changeController("projects"));
                    setPage(2);
                  }}
                />
              </AnimatePresence>
            )}
          </div>
        </div>
        <button
          onClick={() => {
            if (window.location.pathname !== "/") navigate("/");
            dispatch(changeController("contact"));
            setPage(3);
          }}
          className={`hidden items-center h-full px-6 border-l border-genBorder ${
            page === 3 && "border-b border-b-lightOrange"
          } md:flex`}
        >
          <h1 className={`text-${page === 3 ? "whiteText" : "graytext"}`}>
            _contact-me
          </h1>
        </button>
        <button
          onClick={() => {
            dispatch(showMenu(true));
          }}
          className="px-4 md:hidden"
        >
          {menuHamburguesa()}
        </button>
      </div>
    );
  else return <Menu />;
}
