import React from "react";
import { motion } from "framer-motion";

interface Props {
  title: string;
  id?: string | number;
  detail: string;
  image?: string;
  native?: boolean;
  link: string;
}

export default function Card({
  title,
  image,
  id,
  detail,
  native,
  link,
}: Props) {
  return (
    <motion.div
      initial={{ x: -50, opacity: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: 50, opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-11 md:mr-10"
    >
      <span className="text-darkPurple">Project {id}</span>
      <span className="text-graytext">{` // _${title}`}</span>
      <a href={link} target="_blank" rel="noreferrer">
        <div className="relative rounded-2xl bg-projectBg mt-3.5 w-80 h-72 md:h-80 sm:w-96">
          <div className="flex justify-center items-center">
            <img
              className={`rounded-t-2xl h-40 ${!native && "w-full"}`}
              src={
                image ||
                `https://s3-alpha-sig.figma.com/img/c078/b9c3/6775355aae42326ce21aee81340220ed?Expires=1696204800&Signature=Rr1TWKvvpSF7UqCovyyL9kSUKijoLLugRal~kTgK0s3dlTLTP4rxL0-G6SJqQ5R-GGhv9kdZUW1~y-FyZGNnBr7xjHSYoKMmFBUS2yZsFRL67zaR-bAP4lX2kFfE85CN1hL1S9tVK7Z7hoYvl6EgtVW3eKIF~O37Ysa99fjUjoLNBlVUTQM-1zt-Jf64AQALTfwvbQ3HSYnvSB5~QVDBy-f0MgoPBVT9mdbXNOK6h5Q71kzDK1rF6pmB6qdebY4M5DUbY22s9e5x0TSBbmCiX5SreG5CHJvaIFRWq85ntUxHobeejC4OMOZ9vmIGkZ1hdVk0GjMGgDWeZsBKBs~Z7g__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4`
              }
              alt="project_img"
            />
          </div>
          <p className="px-7 text-graytext text-xs pt-5 md:text-sm">{detail}</p>
          {/* <a
          target="_blank"
          rel="noreferrer"
          href={link}
          className="absolute bottom-4"
          >
          <button className="flex text-xs justify-center items-center px-2.5 py-1.5  mt-7 ml-7 rounded-xl text-whiteText bg-[#1C2B3A] md:text-sm md:px-3.5 md:py-2.5">
          view-project
          </button>
        </a> */}
        </div>
      </a>
    </motion.div>
  );
}
