import React, { useEffect, useState } from "react";
import { svgs } from "../../../utils/svgs";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { changeAboutTab } from "../../../redux/slices/aboutSlice";

export default function Interests() {
  const { item, folder, arrow, arrowSmall } = svgs();
  const dispatch = useAppDispatch();
  const [interests, setInterests] = useState(false);
  const [active, setActive] = useState(false);
  const currentTab = useAppSelector((state) => state.about.tab);

  useEffect(() => {
    if (window.innerWidth < 768) setActive(false);
  }, []);
  return (
    <>
      {/* title */}
      <div
        className={`flex items-center py-2.5 border-genBorder border-t mb-1 ${
          active && "border-b"
        } bg-genBorder md:bg-menuBg md:mb-0`}
      >
        <button onClick={() => setActive(!active)} className="pl-3.5 pr-2.5">
          {arrowSmall(active)}
        </button>
        <button
          onClick={() => setActive(!active)}
          className={`text-whiteText md:text-${
            !active && "graytext"
          } text-sm md:text-base`}
        >
          side-interests
        </button>
      </div>
      {/* folders */}
      {active && (
        <div className="text-sm pt-2 md:pt-4 md:mb-4 md:text-base">
          <div className="flex pb-2">
            <div className="flex items-start pt-1">
              <button onClick={() => setInterests(!interests)}>
                {arrow(interests)}
              </button>
              {folder("#EDD109")}
            </div>
            <div className="flex flex-col text-graytext">
              <button onClick={() => setInterests(!interests)} className="flex">
                <p className={`text-${interests ? "whiteText" : "graytext"}`}>
                  interests
                </p>
              </button>
              {interests && (
                <div className="pt-1">
                  <button
                    onClick={() => {
                      dispatch(changeAboutTab("interests"));
                    }}
                  >
                    <div
                      className={`pb-1 flex items-center ${
                        currentTab === "interests" && "text-whiteText"
                      }`}
                    >
                      {item()}gaming
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
