import React from "react";
import { motion } from "framer-motion";

interface Props {
  label: string;
  selected?: boolean;
  onClick: () => void;
  animated?: boolean;
  duration?: number;
}

export default function HeaderButton({
  label,
  selected,
  onClick,
  animated = false,
  duration = 1,
}: Props) {
  return animated ? (
    <motion.button
      initial={{ opacity: 0.5, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration }}
      onClick={onClick}
      className={`flex px-8 justify-center items-center gap-8 border-r border-genBorder ${
        selected && "border-b border-b-lightOrange"
      }`}
    >
      <h1 className={`font-normal text-${selected ? "whiteText" : "graytext"}`}>
        {label}
      </h1>
    </motion.button>
  ) : (
    <button
      onClick={onClick}
      className={`flex px-8 justify-center items-center gap-8 border-r border-genBorder ${
        selected && "border-b border-b-lightOrange"
      }`}
    >
      <h1 className={`font-normal text-${selected ? "whiteText" : "graytext"}`}>
        {label}
      </h1>
    </button>
  );
}
