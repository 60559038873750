import { createSlice } from "@reduxjs/toolkit";
import { DevelopTools } from "../../utils/types";

interface InitialState {
  filters: DevelopTools[];
}

const initialState: InitialState = {
  filters: [],
};

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setTool: (state, action) => {
      if (!state.filters.includes(action.payload))
        state.filters = [...state.filters, action.payload];
    },
    removeTool: (state, action) => {
      const index = state.filters.indexOf(action.payload);
      if (index !== -1) state.filters.splice(index, 1);
    },
  },
});

export const { setTool, removeTool } = projectSlice.actions;
export default projectSlice.reducer;
