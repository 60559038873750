import { DevelopTools } from "./types";

export const allTools = [
  {
    tool: "Typescript",
    icon: DevelopTools.TYPESCRIPT,
  },
  {
    tool: "React",
    icon: DevelopTools.REACT,
  },
  {
    tool: "NextJS",
    icon: DevelopTools.NEXT,
  },
  {
    tool: "Redux",
    icon: DevelopTools.REDUX,
  },
  {
    tool: "Firebase",
    icon: DevelopTools.FIREBASE,
  },
  {
    tool: "Postgres",
    icon: DevelopTools.POSTGRES,
  },
  {
    tool: "Express",
    icon: DevelopTools.EXPRESS,
  },
  {
    tool: "Sequelize",
    icon: DevelopTools.SEQUELIZE,
  },
  {
    tool: "Prisma",
    icon: DevelopTools.PRISMA,
  },
  {
    tool: "Tailwind",
    icon: DevelopTools.TAILWIND,
  },
];
