import React from "react";

interface Props {
  state: {
    message: string;
    user_name: string;
    user_email: string;
  };
}

export default function CodeSnippet({ state }: Props) {
  const lines = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const date = new Date().toDateString();

  return (
    <div className="hidden items-center pb-36 max-w-xl pr-4 lg:flex">
      {/* Code Snippet */}
      <div>
        <div className="text-graytext pl-4 pr-6 pt-2">
          {lines.map((e, i) => {
            return <p key={i}>{i + 1}</p>;
          })}
        </div>
      </div>
      <div className="hidden pt-2 text-darkPurple lg:block">
        <p>
          <span className="text-darkPink">const </span>
          <span>button </span>
          <span className="text-darkPink">= </span>
          <span>document.querySelector</span>
          <span className="text-graytext">{`(`}</span>
          <span className="text-lightOrange">'#sendBtn'</span>
          <span className="text-graytext">{`);`}</span>
        </p>
        <br></br>
        <p>
          <span className="text-darkPink">const </span>
          <span> message </span>
          <span className="text-darkPink">= </span>
          <span className="text-graytext">{`{`} </span>
        </p>
        <p>
          <span>&nbsp;name: </span>
          <span className="text-lightOrange">"{state.user_name}"</span>
          <span className="text-graytext">,</span>
        </p>
        <p>
          <span>&nbsp;email: </span>
          <span className="text-lightOrange">"{state.user_email}"</span>
          <span className="text-graytext">,</span>
        </p>
        <p>
          <span>&nbsp;message: </span>
          <span className="text-lightOrange break-all">"{state.message}"</span>
          <span className="text-graytext">,</span>
        </p>
        <p>
          <span className="text-graytext">&nbsp;date: </span>
          <span className="text-lightOrange">"{date}"</span>
        </p>
        <p className="text-graytext">{`}`}</p>
        <br></br>
        <p>
          <span>button.addEventListener</span>
          <span className="text-graytext">{`(`}</span>
          <span className="text-lightOrange">'click'</span>
          <span className="text-graytext">, () </span>
          <span className="text-darkPink">{`=> `}</span>
          <span className="text-graytext">{`{`}</span>
        </p>
        <p>
          <span>form.send</span>
          <span className="text-graytext">{`(`}</span>
          <span>message</span>
          <span className="text-graytext">{`);`}</span>
        </p>
        <p>
          <span className="text-graytext">{`})`}</span>
        </p>
      </div>
    </div>
  );
}
