import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function Backend() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);

  return (
    <>
      {/* Summary */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// professional-info `}</span>
        <span className="text-graytext"> / backend</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-6 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>
          As an experienced backend development professional, I bring a wealth
          of expertise and skills to the table. My proficiency in programming
          languages such as JavaScript (Node.js) and Python empowers me to craft
          highly efficient and scalable systems that underpin web applications
          and online services. These languages serve as the foundation upon
          which I build robust digital infrastructures, ensuring optimal
          performance and functionality.
        </p>
        <br />
        <p>
          In the ever-evolving landscape of backend development, my knowledge
          extends to an array of frameworks and libraries, including but not
          limited to Express.js, Prisma, and Sequelize. These tools enable me to
          architect secure and high-performance backend applications that meet
          the most demanding project requirements. My commitment to staying
          updated with emerging technologies and best practices ensures that I
          remain at the forefront of innovative backend development.
        </p>
        <br />
        <p>
          Furthermore, my extensive experience spans a wide spectrum of database
          technologies. I have successfully navigated the intricacies of NoSQL
          databases such as MongoDB and Firebase Realtime Database, as well as
          mastered the nuances of relational databases like PostgreSQL and
          SQLite. This breadth of experience equips me to adapt to diverse
          project needs, whether it's managing complex data structures or
          ensuring data security and integrity.
        </p>
        <br />
        <p>
          Beyond my technical prowess, I pride myself on my ability to
          collaborate effectively with cross-functional teams, communicate
          complex technical concepts to non-technical stakeholders, and solve
          intricate challenges that arise during the development process. My
          dedication to continuous learning and professional growth drives me to
          explore new technologies, embrace evolving methodologies, and expand
          my horizons in the ever-exciting field of backend development.
        </p>
        <br />
        <p>
          In summary, I am a seasoned backend developer with a strong foundation
          in JavaScript, backed by a deep knowledge of frameworks and databases.
          My passion for building efficient, secure, and high-performance
          systems, along with my commitment to ongoing improvement, positions me
          as a valuable asset in delivering exceptional web applications and
          online services.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">*/</p>
      </div>
    </>
  );
}
