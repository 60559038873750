import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function University() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);
  return (
    <>
      {/* Skills */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// professional-info `}</span>
        <span className="text-graytext"> / university</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-6 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>* Education: Universidad del Desarrollo, Chile</p>
        <br />
        <p>* Career: Bussiness Administration</p>
        <br />
        <p>* Degree: Engineering, Finance</p>
        <br />
        <p>
          * Academic Excellence: Consistently achieved high scores throughout my
          career
        </p>
        <br />
        <p>
          * Dual Interests: Interest in economics, finance and the business
          world. Passion for management and decision making.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">*/</p>
      </div>
    </>
  );
}
