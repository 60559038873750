import React, { useEffect, useState } from "react";
import { svgs } from "../../../utils/svgs";

export default function Contact() {
  const { arrowSmall, contact } = svgs();
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 768) setActive(false);
  }, []);
  return (
    <>
      {/* title */}
      <div className="flex items-center py-2.5 border-genBorder border-t border-b bg-genBorder md:bg-menuBg">
        <button onClick={() => setActive(!active)} className="pl-3.5 pr-2.5">
          {arrowSmall(active)}
        </button>
        <button
          onClick={() => setActive(!active)}
          className={`text-whiteText md:text-${
            !active && "graytext"
          } text-sm md:text-base`}
        >
          contact
        </button>
      </div>
      {active && (
        <div className="text-sm pt-4 md:text-base">
          <div className="flex items-center pb-3">
            {contact()}
            <p className="text-graytext text-sm">diegocerdacelis@hotmail.com</p>
          </div>
          <div className="flex items-center pb-3">
            {contact(true)}
            <p className="text-graytext text-sm">+569 81895888</p>
          </div>
        </div>
      )}
    </>
  );
}
