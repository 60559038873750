import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function Developer() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);
  return (
    <>
      {/* Skills */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// professional-info `}</span>
        <span className="text-graytext"> / skills</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-6 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>&nbsp;* Frontend Languages: Javascript, Typescript</p>
        <br />
        <p>&nbsp;* Frontend Frameworks: React, React-Native, Vue, NextJS</p>
        <br />
        <p>&nbsp;* Frontend Styling: TailwindCSS, CSS, Bootstrap, ChakraUI</p>
        <br />
        <p>&nbsp;* Frontend Animation: Framer Motion</p>
        <br />
        <p>&nbsp;* Backend Languages: NodeJS, Python</p>
        <br />
        <p>&nbsp;* Backend Frameworks: Express</p>
        <br />
        <p>&nbsp;* Databases: PostgreSQL, MongoDB, Firebase, SQLite</p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">&nbsp;*/</p>
      </div>
    </>
  );
}
