import React from "react";
import { svgs } from "../../utils/svgs";

interface Props {
  label: string;
  icon?: boolean;
  title?: boolean;
  onClick?: (x: any) => void;
}

export default function Button({ icon, label, title, onClick }: Props) {
  const { cerrarHamburguesa } = svgs();
  return (
    <div className="flex">
      <button
        onClick={onClick}
        className="flex items-center justify-between w-full h-14 max-h-14 px-6 border-b border-genBorder"
      >
        <h1 className={`text-${title ? "graytext" : "whiteText"}`}>{label}</h1>
        {icon && cerrarHamburguesa()}
      </button>
    </div>
  );
}
