import React from "react";

interface Props {
  setStep: (e: any) => void;
  setState: (e: any) => void;
}

export default function Confirmed({ setStep, setState }: Props) {
  return (
    <div className="flex justify-center items-center w-full pb-24 pt-10 border-r border-genBorder max-w-4xl md:w-1/2 md:pt-0">
      {/* Form Sent Message */}
      <div className="flex flex-col w-full text-graytext md:w/35">
        <h1 className="text-whiteText text-center text-3xl mb-2.5">
          Thank you! 🤘
        </h1>
        <h2 className="text-lg text-center text-graytext">
          Your message has been accepted. You will receive answer really soon!
        </h2>
        <div className="flex items-center justify-center">
          <button
            onClick={() => {
              setState({
                email: "",
                name: "",
                message: "",
              });
              setStep(0);
            }}
            className="bg-buttonBg w-1/2 rounded-lg px-3.5 py-2.5 text-whiteText mt-6"
          >
            send-new-message
          </button>
        </div>
      </div>
    </div>
  );
}
