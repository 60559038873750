import { configureStore } from "@reduxjs/toolkit";
import aboutReducer from "./slices/aboutSlice";
import projectReducer from "./slices/projectSlice";
import controllerReducer from "./slices/controllerSlice";
import snakeReducer from "./slices/snakeSlice";

export const store = configureStore({
  reducer: {
    about: aboutReducer,
    projects: projectReducer,
    controller: controllerReducer,
    snake: snakeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
