import React, { useEffect, useState } from "react";
import { svgs } from "../../../utils/svgs";
import { allTools } from "../../../utils/tools";
import { useAppDispatch } from "../../../redux/hooks";
import { removeTool, setTool } from "../../../redux/slices/projectSlice";

interface State {
  react: boolean;
  next: boolean;
  typescript: boolean;
  redux: boolean;
  firebase: boolean;
  postgres: boolean;
  express: boolean;
  sequelize: boolean;
  prisma: boolean;
  tailwind: boolean;
}

export default function Tools() {
  const dispatch = useAppDispatch();
  const { arrowSmall, developTool, checkBox } = svgs();
  const [active, setActive] = useState(true);
  const [state, setState] = useState<State>({
    react: false,
    next: false,
    typescript: false,
    redux: false,
    firebase: false,
    postgres: false,
    express: false,
    sequelize: false,
    prisma: false,
    tailwind: false,
  });

  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.currentTarget.name]: !state[e.currentTarget.name as keyof State],
    });

    if (state[e.currentTarget.name as keyof State])
      dispatch(setTool(e.currentTarget.name));
    else dispatch(removeTool(e.currentTarget.name));
  };

  useEffect(() => {
    for (const tool in state) {
      if (state[tool as keyof State]) dispatch(setTool(tool));
      else dispatch(removeTool(tool));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (window.innerWidth < 768) setActive(false);
  }, []);

  return (
    <>
      {/* title */}
      <div className="flex items-center py-2.5 border-b border-r border-genBorder md:max-w-xs md:w-72 bg-genBorder md:bg-menuBg">
        <div className="pl-3.5 pr-2.5">{arrowSmall(active)}</div>
        <button
          onClick={() => setActive(!active)}
          className={`text-whiteText md:text-${!active && "graytext"}`}
        >
          projects
        </button>
      </div>
      {/* developing tools */}
      {active && (
        <div className="mb-4 text-xs md:text-base">
          {allTools.map((t, i) => {
            return (
              <button
                name={t.icon}
                onClick={handleChange}
                key={i}
                className="flex items-center pl-5 pt-3"
              >
                <div onClick={handleChange} className="pr-5">
                  {checkBox(state[t.icon as keyof State])}
                </div>
                <div className="pr-2">
                  {developTool(t.icon, state[t.icon as keyof State])}
                </div>
                <span className="text-whiteText">{t.tool}</span>
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
