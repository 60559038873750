import React, { useState } from "react";
import Snake from "./Snake";
import { svgs } from "../../utils/svgs";
import { useAppSelector } from "../../redux/hooks";

export default function Container() {
  const { snakeDot, snakeFood, keyboardArrow } = svgs();
  const { winner, gameOver } = useAppSelector((state) => state.snake);

  const [foodLeft, setFoodLeft] = useState([1, 2, 3, 4, 5]);
  return (
    <div className="flex relative rounded-lg border border-[#111827] bg-gradient-to-b from-opacity-70 via-opacity-70 via-[#22c55e] to-opacity-9 shadow-inner shadow-2xl backdrop-blur-2xl p-4">
      <div className="absolute bottom-3">{snakeDot()}</div>
      <div className="absolute">{snakeDot()}</div>
      <div className="absolute right-3">{snakeDot()}</div>
      <div className="absolute right-3 bottom-3">{snakeDot()}</div>
      <div className="flex my-8 ml-6 h-full w-full rounded-lg bg-[rgba(1,22,39,0.84)]">
        <Snake foodLeft={foodLeft} setFoodLeft={setFoodLeft} />
      </div>
      <div className="relative mr-6">
        {/* Arrows */}
        <div className="w-[181px] h-[142px] bg-[rgba(1,20,35,0.19)] my-8 ml-8 rounded-lg">
          <div className="flex flex-col mt-3.5 text-sm text-whiteText">
            <div className="px-3.5">
              <p className="mt-3">{`// use keyboard`}</p>
              <p>{`// keyboard arrows`}</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="mt-3.5">{keyboardArrow()}</div>
              <div className="flex">
                {keyboardArrow("left")}
                {keyboardArrow("down")}
                {keyboardArrow("right")}
              </div>
            </div>
          </div>
        </div>
        {/* Food */}
        <div className="flex flex-col ml-8 text-xs text-whiteText">
          <p className="mb-2 px-3.5">{`// Food left`}</p>
          <div className="flex px-3.5">
            {foodLeft.map((f, i) => {
              return snakeFood(i);
            })}
          </div>
        </div>
        {(gameOver || winner) && (
          <div className="flex justify-center ml-8 bg-[rgba(1,22,39,0.84)] text-[#43D9AD] text-xl mt-8">
            <p className="py-3">{winner ? "WELL DONE!" : "GAME OVER"}</p>
          </div>
        )}
      </div>
    </div>
  );
}
