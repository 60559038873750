export enum DevelopTools {
  REACT = "react",
  REACT_NATIVE = "react-native",
  EXPRESS = "express",
  PRISMA = "prisma",
  SEQUELIZE = "sequelize",
  FIREBASE = "firebase",
  TYPESCRIPT = "typescript",
  REDUX = "redux",
  POSTGRES = "postgres",
  TAILWIND = "tailwind",
  BOOTSTRAP = "bootstrap",
  CHAKRAUI = "chakraui",
  NEXT = "next",
}

export interface Projects {
  id: number;
  title: string;
  detail: string;
  image: string;
  link: string;
  native?: boolean;
  technologies: DevelopTools[];
}
