import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useAppSelector } from "../../redux/hooks";
import { projects } from "../../utils/constants";
import { Projects } from "../../utils/types";
import NoProjects from "./NoProjects";
import { AnimatePresence } from "framer-motion";

export default function MyProjects() {
  const filters = useAppSelector((state) => state.projects.filters);
  const [showed, setShowed] = useState(projects);

  useEffect(() => {
    if (filters.length > 0) {
      let match: Projects[] = [];
      for (const filter of filters) {
        for (const project of projects) {
          if (project.technologies.includes(filter)) {
            match.push(project);
          }
        }
      }

      match = match.filter((item, index) => match.indexOf(item) === index);
      match = match.sort((a, b) => (a.id > b.id ? 1 : -1));

      setShowed([...match]);
    } else setShowed([...projects]);
  }, [filters]);

  if (!showed.length)
    return (
      <div className="w-full">
        <NoProjects />;
      </div>
    );

  return (
    <div className="flex w-full justify-center flex-wrap overflow-y-scroll md:mt-12">
      <div className="flex flex-wrap justify-center w-full md:pl-20 md:justify-start">
        <AnimatePresence>
          {showed.map((p, i) => {
            return (
              <Card
                key={i}
                title={p.title}
                detail={p.detail}
                id={p.id}
                image={p.image}
                native={p.native}
                link={p.link}
              />
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
}
