import React, { useState } from "react";
import { svgs } from "../../utils/svgs";

const initialState = {
  linkedin: false,
  facebook: false,
  github: false,
};

export default function Footer() {
  const { github, facebook, linkedin } = svgs();
  const [state, setState] = useState(initialState);

  return (
    <div className="hidden justify-between w-full h-12 absolute bottom-0 border-t border-genBorder md:flex">
      <div className="flex">
        <div className="flex items-center pl-5 pr-4 h-full border-r border-genBorder">
          <h1 className="text-graytext">find me in:</h1>
        </div>
        <button
          onMouseEnter={() => setState({ ...initialState, linkedin: true })}
          onMouseLeave={() => setState({ ...initialState })}
          className="flex px-3.5 items-center border-genBorder border-r"
        >
          <a
            href="https://www.linkedin.com/in/diegocerdacelis/"
            target="_blank"
            rel="noreferrer"
          >
            {linkedin(state.linkedin)}
          </a>
        </button>
        <button
          onMouseEnter={() => setState({ ...initialState, facebook: true })}
          onMouseLeave={() => setState({ ...initialState })}
          className="flex px-3.5 items-center border-r border-genBorder"
        >
          <a
            href="https://www.facebook.com/diego.cerda.celis/"
            target="_blank"
            rel="noreferrer"
          >
            {facebook(state.facebook)}
          </a>
        </button>
      </div>
      <button
        onMouseEnter={() => setState({ ...initialState, github: true })}
        onMouseLeave={() => setState({ ...initialState })}
        className="flex items-center px-6 border-l border-genBorder"
      >
        <a
          href="https://github.com/Xxmbxxmb"
          target="_blank"
          rel="noreferrer"
          className="flex"
        >
          <h1
            className={`mr-2 text-${state.github ? "whiteText" : "graytext"}`}
          >
            @Xxmbxxmb
          </h1>
          {github(state.github)}
        </a>
      </button>
    </div>
  );
}
