import React from "react";
import NotFoundLogo from "../assets/NotFound.png";

export default function NotFound() {
  return (
    <div className="flex justify-center items-center h-full pt-14 pb-12">
      <img src={NotFoundLogo} alt="not_found" />
    </div>
  );
}
