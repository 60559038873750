import React, { useState } from "react";
import SideBar from "../components/Contact/SideBar/SideBar";
import Form from "../components/Contact/Form";
import CodeSnippet from "../components/Contact/CodeSnippet";
import Confirmed from "../components/Contact/Confirmed";
import { motion } from "framer-motion";
import { useAppSelector } from "../redux/hooks";

export default function Contact() {
  const [state, setState] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const { menu } = useAppSelector((state) => state.controller);
  const [step, setStep] = useState(0);

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 1 }}
      className={`flex flex-col h-full pt-14 pb-12 md:flex-row ${
        menu && "hidden"
      }`}
    >
      <SideBar />
      {step === 0 ? (
        <Form setState={setState} state={state} setStep={setStep} />
      ) : (
        <Confirmed setStep={setStep} setState={setState} />
      )}
      <CodeSnippet state={state} />
    </motion.div>
  );
}
