import { DevelopTools, Projects } from "./types";
import Checkout from "../assets/checkout.png";
import Dashboard from "../assets/dashboard.png";
import App from "../assets/App.png";
import Implementation from "../assets/Implementation.png";

export const lineCodes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100,
];

export const projects: Projects[] = [
  {
    id: 1,
    title: "pokemon-pi",
    detail:
      "My first React project, is a pokedex where you can create your own pokemons.",
    image: "https://i.imgur.com/xuNwBBZ.png",
    link: "https://github.com/Xxmbxxmb/Pokemon",
    technologies: [
      DevelopTools.EXPRESS,
      DevelopTools.POSTGRES,
      DevelopTools.REACT,
      DevelopTools.REDUX,
      DevelopTools.SEQUELIZE,
    ],
  },
  {
    id: 2,
    title: "prodemaster",
    detail: "Sports betting platform to compete with friends for cash prizes. ",
    image: "https://i.imgur.com/Gu9Zzqu.png",
    link: "https://github.com/Xxmbxxmb/PG-Henry",
    technologies: [
      DevelopTools.CHAKRAUI,
      DevelopTools.EXPRESS,
      DevelopTools.POSTGRES,
      DevelopTools.PRISMA,
      DevelopTools.REACT,
      DevelopTools.REDUX,
      DevelopTools.TYPESCRIPT,
    ],
  },
  {
    id: 3,
    title: "mjinvestments",
    detail:
      "First project as a freelancer, cryptocurrency arbitrage platform that gets prices from different exchanges.",
    image: "https://i.imgur.com/pHkBt0L.png",
    link: "https://mjcinversiones.netlify.app/",
    technologies: [
      DevelopTools.CHAKRAUI,
      DevelopTools.PRISMA,
      DevelopTools.REDUX,
      DevelopTools.REACT,
      DevelopTools.POSTGRES,
      DevelopTools.EXPRESS,
      DevelopTools.TYPESCRIPT,
    ],
  },
  {
    id: 4,
    title: "movies-app",
    detail:
      "My first project using React Native, a movie recommender for iOS and Android.",
    image: "https://i.imgur.com/Nya5ioB.jpg",
    link: "https://github.com/Xxmbxxmb/PeliculasApp",
    native: true,
    technologies: [
      DevelopTools.REACT,
      DevelopTools.REDUX,
      DevelopTools.TYPESCRIPT,
    ],
  },
  {
    id: 5,
    title: "sphereone-checkout",
    detail:
      "Checkout that allows registered merchants to sell their products through our cryptocurrency payment system.",
    image: Checkout,
    link: "https://checkout.sphereone.xyz/",
    technologies: [
      DevelopTools.EXPRESS,
      DevelopTools.FIREBASE,
      DevelopTools.REACT,
      DevelopTools.REDUX,
      DevelopTools.TAILWIND,
      DevelopTools.TYPESCRIPT,
    ],
  },
  {
    id: 6,
    title: "sphereone-dashboard",
    detail:
      "Page to manage the registered merchant, create charges, view balances, make real and test payments (testnet), among other things.",
    image: Dashboard,
    link: "https://dashboard.sphereone.xyz/",
    technologies: [
      DevelopTools.EXPRESS,
      DevelopTools.FIREBASE,
      DevelopTools.REACT,
      DevelopTools.REDUX,
      DevelopTools.TAILWIND,
      DevelopTools.TYPESCRIPT,
    ],
  },
  {
    id: 7,
    title: "sphereone-app",
    detail:
      "App that allows to manage the Sphereone user, also to view balances, make P2P payments, onramp funds, among other things.",
    image: App,
    link: "https://wallet.sphereone.xyz/",
    native: true,
    technologies: [
      DevelopTools.EXPRESS,
      DevelopTools.FIREBASE,
      DevelopTools.REACT,
      DevelopTools.REDUX,
      DevelopTools.TAILWIND,
      DevelopTools.TYPESCRIPT,
    ],
  },
  {
    id: 8,
    title: "nft-implementation",
    detail:
      "NFTs Marketplace, which uses SphereOne cryptocurrency payment system. It also includes a game where you can use these NFTs.",
    image: Implementation,
    link: "https://sphereoneimplementation.web.app/",
    technologies: [
      DevelopTools.EXPRESS,
      DevelopTools.FIREBASE,
      DevelopTools.NEXT,
      DevelopTools.REDUX,
      DevelopTools.TAILWIND,
      DevelopTools.TYPESCRIPT,
    ],
  },
];
