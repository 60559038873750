import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function Overview() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);
  return (
    <>
      {/* Overview */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// personal-info `}</span>
        <span className="text-graytext"> / overview</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-8 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>
          Hello, fellow technology enthusiast. I'm Diego Cerda, a highly
          versatile professional with a strong background in Business
          Administration and a degree in Finance. My career began in the world
          of finance, where I gained a deep understanding of financial
          management, data analysis and strategic decision making. However, my
          passion for technology led me to embark on an exciting journey as a
          Full Stack developer.
        </p>
        <br />
        <p>
          My ability to combine my financial knowledge with my development
          experience has allowed me to approach business challenges from a
          unique perspective. I have a results-oriented approach and an innate
          ability to analyze complex data, which has helped me design effective
          technology solutions that drive business success.
        </p>
        <br />
        <p>
          Since I started this journey, I have been a dedicated software
          developer with a deep-rooted love for technology. I'm constantly
          exploring the latest trends and pushing the boundaries to create
          impactful solutions.
        </p>
        <br />
        <p>
          In my current role as a Full Stack Developer, I have the opportunity
          to apply my diversified skill set to create applications and systems
          that improve operational efficiency and deliver exceptional user
          experiences. I was one of the first members of the team, where I have
          played an important role in contributing within all stages of product
          development.
        </p>
        <br />
        <p>
          My passion for constant learning has led me to always stay on top of
          the latest technology trends and venture into new digital territories.
          In particular, my recent foray into the world of Web3 has been an
          exciting journey. As a Web3 enthusiast, I have been exploring
          technologies such as blockchain, smart contracts and decentralized
          applications (dApps). This experience has given me a deeper
          understanding of asset tokenization and decentralized governance.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">*/</p>
      </div>
    </>
  );
}
