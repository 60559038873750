import React from "react";
import { useAppSelector } from "../../redux/hooks";
import Summary from "./Professional/Summary";
import Developer from "./Professional/Developer";
import University from "./Professional/University";
import Backend from "./Professional/Backend";
import Frontend from "./Professional/Frontend";
import Overview from "./Personal/Overview";
import Gaming from "./Interests/Gaming";
import Professional from "./Professional/Professional";

export default function Data() {
  const tab = useAppSelector((state) => state.about.tab);

  if (tab === "summary") return <Summary />;
  else if (tab === "developer") return <Developer />;
  else if (tab === "backend") return <Backend />;
  else if (tab === "frontend") return <Frontend />;
  else if (tab === "university") return <University />;
  else if (tab === "overview") return <Overview />;
  else if (tab === "interests") return <Gaming />;
  else if (tab === "professional") return <Professional />;
  else return <Frontend />;
}
