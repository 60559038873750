import React from "react";
import Tools from "./Tools";

export default function SideBar() {
  return (
    <>
      {/* Left Sidebar */}
      <div className="flex flex-col border-r border-genBorder mb-4 md:w-72 md:h-full md:max-w-xs">
        <Tools />
      </div>
    </>
  );
}
