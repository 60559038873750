import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function Professional() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);
  return (
    <>
      {/* Professional */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// professional-info `}</span>
        <span className="text-graytext"> / professional</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-6 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p className="text-whiteText"># MJInvestments:</p>
        <br />
        <p>
          Freelance development project with the objective of designing and
          implementing a cryptocurrency arbitrage platform. This platform,
          designed to operate in a high volatility and fast-paced environment,
          was conceived with the purpose of allowing users to access real-time
          prices, as well as the buy and sell books of various cryptocurrency
          exchanges. This core functionality provided users with the ability to
          calculate profit opportunities by identifying price discrepancies
          between different exchanges.
        </p>
        <br />
        <p>
          The project involved the meticulous integration of APIs from multiple
          internationally renowned cryptocurrency exchanges. Through the use of
          WebSockets, real-time market data was achieved, providing users with
          an up-to-date and accurate view of the cryptocurrency landscape at all
          times.
        </p>
        <br />
        <p>
          The resulting platform not only allowed users to efficiently track and
          analyze arbitrage opportunities, but also incorporated robust security
          measures to ensure data integrity and protection of user's
          investments. In addition, advanced features such as real-time
          notifications and technical analysis tools were implemented to further
          enrich the user experience and provide users with the necessary tools
          to make informed decisions in a highly dynamic market.
        </p>
        <br />
        <p className="text-whiteText"># SphereOne:</p>
        <br />
        <p>
          I play a vital role as one of the early team members, extensively
          involved in all aspects of the product. My contributions focused on
          developing core features, including payment routes creation and
          execution, I was in charge of the creation of the SDK that is
          delivered to customers in order to implement our services in their
          products.
        </p>
        <br />
        <p>
          Also gained proficiency in app development using React Native and
          actively utilized my product ownership skills to enhance the user
          experience. Additionally, I immersed myself in the Web3 world,
          developing solutions involving interact directly with the blockchain,
          smart wallets, decentralized applications (dApps).
        </p>
        <br />
        <p>
          Agile in approach, I consistently delivered high-quality results
          within demanding deadlines. Not only developed code but also I’ve been
          part of the QA testing. Super active team member, jumping in calls
          every time to provide help where’s needed and contribute feedback on
          code quality for pull requests reviews. Also worked in web development
          with react, typescript, tailwindcss, redux, and more.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">*/</p>
      </div>
    </>
  );
}
