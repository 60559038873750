import React, { useState } from "react";
import { svgs } from "../../../utils/svgs";

export default function ContactTab() {
  const { arrowSmall, contact } = svgs();
  const [active, setActive] = useState(true);

  return (
    <>
      {/* contact */}
      <div
        className={`flex items-center py-2.5 border-genBorder border-b
         bg-genBorder mb-1 md:mb-0 md:bg-menuBg`}
      >
        <button onClick={() => setActive(!active)} className="pl-3.5 pr-2.5">
          {arrowSmall(active)}
        </button>
        <button
          onClick={() => setActive(!active)}
          className={`text-whiteText md:text-${
            !active && "graytext"
          } text-sm md:text-base`}
        >
          contacts
        </button>
      </div>

      {active && (
        <div className="pt-4">
          <div className="flex items-center pb-3">
            {contact()}
            <p className="text-graytext text-sm">diegocerdacelis@hotmail.com</p>
          </div>
          <div className="flex items-center pb-3">
            {contact(true)}
            <p className="text-graytext text-sm">+569 81895888</p>
          </div>
        </div>
      )}
    </>
  );
}
