import React from "react";
import { svgs } from "../../utils/svgs";

export default function Footer() {
  const { linkedin, github, facebook } = svgs();
  return (
    <div className="flex w-full items-center justify-between border-t border-genBorder pl-5 md:hidden">
      <h1 className="text-graytext">find me in: </h1>
      <div className="flex">
        <a
          href="https://www.linkedin.com/in/diegocerdacelis/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="px-4 py-3 border-l border-genBorder">
            {linkedin()}
          </div>
        </a>
        <a
          href="https://www.facebook.com/diego.cerda.celis/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="px-4 py-3 border-l border-genBorder">
            {facebook()}
          </div>
        </a>
        <a href="https://github.com/Xxmbxxmb" target="_blank" rel="noreferrer">
          <div className="px-4 py-3 border-l border-genBorder">{github()}</div>
        </a>
      </div>
    </div>
  );
}
