import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function Frontend() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);

  return (
    <>
      {/* Summary */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// professional-info `}</span>
        <span className="text-graytext"> / frontend</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-6 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>
          While my main area of expertise lies in backend development, I possess
          a well-rounded skill set that extends to frontend development as well.
        </p>
        <br />
        <p>
          I have a strong background in creating engaging and functional user
          experiences using technologies such as React, React Native, Vue.js and
          Next.js. My focus is on building effective user interfaces and
          optimizing the performance of web and mobile applications.
        </p>
        <br />
        <p>
          My knowledge in styling and layout implementation includes using
          Tailwind CSS, CSS and HTML to create responsive and modern designs. In
          addition, I am familiar with additional tools and technologies such as
          Redux for state management and Webpack for resource optimization.
        </p>
        <br />
        <p>
          My commitment to quality is reflected in my test writing and constant
          pursuit of innovative and efficient frontend development practices. I
          am excited to continue exploring new technologies and approaches in
          the exciting world of frontend development.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">*/</p>
      </div>
    </>
  );
}
