import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router";
import NotFound from "./pages/NotFound";
import Animation from "./pages/Animation";

function App() {
  return (
    <div className="h-screen relative">
      <Header />
      <Routes>
        <Route path="/" element={<Animation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
