import React from "react";
import { lineCodes } from "../../../utils/constants";

export default function Gaming() {
  return (
    <>
      {/* Gaming */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// side-interests `}</span>
        <span className="text-graytext"> / gaming</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-8 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>
          Gaming has always been one of my deepest passions. I greatly enjoy
          exploring virtual worlds and participating in exciting games.
        </p>
        <br />
        <p>
          One of the games that has always held a special place in my heart is
          the Final Fantasy saga. The game mechanics, exciting combat and, above
          all, its characters and the depth of its story, never fail to
          captivate me. It's a game I always come back to, because every time I
          dive into its worlds, I feel like I'm living a unique and
          unforgettable adventure.
        </p>
        <br />
        <p>
          But my love for games goes beyond Final Fantasy. I've had the pleasure
          of delving into a wide variety of gaming experiences. Titles like
          World of Warcraft, Civilization, Fortnite, Overwatch, CSGO, the Call
          of Duty series of games, and Runescape have left an indelible mark on
          my gaming journey.
        </p>
        <br />
        <p>
          I tell you what, gaming is one great goddamn emotional support. As a
          passionate gamer, I'm always eager to discover new titles and embrace
          the ever evolving world of gaming.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">*/</p>
      </div>
    </>
  );
}
