import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import emailjs from "@emailjs/browser";
import { isEmail } from "../../utils/validations";

interface Props {
  setState: (e: any) => void;
  setStep: (e: any) => void;
  state: {
    user_email: string;
    message: string;
    user_name: string;
  };
}

export default function Form({ state, setState, setStep }: Props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const form: any = useRef();
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) return;
    if (loading) {
      setLoading(false);
      return;
    }

    const emailResponse = await emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_ID as string,
      process.env.REACT_APP_EMAIL_TEMPLATE as string,
      form.current,
      process.env.REACT_APP_EMAIL_PUBLIC as string
    );

    if (emailResponse.status === 200) setStep(1);
    else alert("Something went wrong... Please try again.");
    setLoading(false);
  };

  const validate = () => {
    setLoading(true);
    const error = {
      name: "",
      email: "",
      message: "",
    };
    if (state.user_name === "") error.name = "Empty name";
    if (state.user_email === "") error.email = "Empty email";
    if (!isEmail(state.user_email)) error.email = "Invalid email";
    if (state.message === "") error.message = "Empty message";
    setErrors({ ...error });
    if (error.email !== "" || error.message !== "" || error.name !== "") {
      setLoading(false);
      return false;
    }

    return true;
  };

  useEffect(() => {
    setErrors({
      name: "",
      email: "",
      message: "",
    });
  }, [state]);

  return (
    <div className="flex justify-center items-center w-full pb-24 border-r border-genBorder lg:max-w-4xl lg:w-1/2">
      {/* Form */}
      <form
        ref={form}
        onSubmit={handleSubmit}
        className="flex flex-col text-graytext w-full px-7 max-w-xl md:max-w-full md:w-2/5 md:px-0"
      >
        <label className="mb-2.5">_name</label>
        <input
          value={state.user_name}
          onChange={handleChange}
          type="text"
          name="user_name"
          className={`rounded-lg border border-${
            errors.name ? "lightRed" : "graytext"
          } bg-projectBg h-10 w-full mb-6 px-2`}
        />
        <label className="mb-2.5">_email</label>
        <input
          value={state.user_email}
          onChange={handleChange}
          type="email"
          name="user_email"
          className={`rounded-lg border border-${
            errors.email ? "lightRed" : "graytext"
          } bg-projectBg h-10 w-full mb-6 px-2`}
        />
        <label className="mb-2.5">_message</label>
        <textarea
          value={state.message}
          onChange={handleChange}
          name="message"
          className={`p-2 rounded-lg border border-${
            errors.message ? "lightRed" : "graytext"
          } bg-projectBg w-full mb-6 px-2 h-32 md:h-40`}
        />
        <button
          type="submit"
          className="bg-buttonBg w-1/2 rounded-lg px-3.5 py-2.5 text-whiteText text-sm"
        >
          {loading ? (
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="#1E2D3D"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#607B96"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            "submit-message"
          )}
        </button>
      </form>
    </div>
  );
}
