import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "home",
  menu: false,
};

const controllerSlice = createSlice({
  name: "controlle",
  initialState,
  reducers: {
    changeController: (state, action) => {
      state.tab = action.payload;
    },
    showMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
});

export const { changeController, showMenu } = controllerSlice.actions;
export default controllerSlice.reducer;
