import React from "react";
import PersonalInfo from "./Personal";
import Contact from "./Contact";
import ProfessionalInfo from "./Professional";
import Interests from "./Interests";
import "./SideBar.css";

export default function SideBar() {
  return (
    <>
      {/* Left SideBar */}
      <div className="flex flex-col h-full max-h-48 border-genBorder overflow-y-scroll md:max-w-xs md:w-72 md:border-r md:h-full md:max-h-full">
        <ProfessionalInfo />
        <PersonalInfo />
        <Interests />
        <Contact />
      </div>
    </>
  );
}
