import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: "summary",
};

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    changeAboutTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { changeAboutTab } = aboutSlice.actions;
export default aboutSlice.reducer;
