import React from "react";
import SideBar from "../components/About/SideBar/SideBar";
import Data from "../components/About/Data";
import { motion } from "framer-motion";
import { useAppSelector } from "../redux/hooks";

export default function About() {
  const { menu } = useAppSelector((state) => state.controller);
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 1 }}
      key={"aboutScreen"}
      className={`flex h-full pt-14 flex-col pb-12 md:flex-row ${
        menu && "hidden"
      }`}
    >
      <SideBar />
      <Data />
    </motion.div>
  );
}
