import React from "react";
import { AnimatePresence } from "framer-motion";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";
import { useAppSelector } from "../redux/hooks";
import NotFound from "./NotFound";

function Animation() {
  const page = useAppSelector((state) => state.controller.tab);

  return (
    <AnimatePresence mode="wait">
      {page === "home" ? (
        <Home key="homeScreen" />
      ) : page === "about" ? (
        <About key="aboutScreen" />
      ) : page === "projects" ? (
        <Projects key="projectScreen" />
      ) : page === "contact" ? (
        <Contact key="contactScreen" />
      ) : (
        <NotFound />
      )}
    </AnimatePresence>
  );
}

export default Animation;
