import React from "react";
import ContactTab from "./ContactTab";

export default function SideBar() {
  return (
    <>
      {/* Left SideBar */}
      <div className="flex flex-col mb-6 border-r border-genBorder md:max-w-xs md:w-72 md:h-full md:mb-0">
        <ContactTab />
      </div>
    </>
  );
}
