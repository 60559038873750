import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  gameOver: boolean;
  winner: boolean;
}

const initialState: InitialState = {
  gameOver: false,
  winner: false,
};

const snakeSlice = createSlice({
  name: "snake",
  initialState,
  reducers: {
    setGameState: (state, action) => {
      state.gameOver = action.payload;
    },
    setWinner: (state, action) => {
      if (!state.winner) state.winner = action.payload;
    },
  },
});

export const { setGameState, setWinner } = snakeSlice.actions;
export default snakeSlice.reducer;
