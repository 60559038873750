import React from "react";
import Button from "./Button";
import { useAppDispatch } from "../../redux/hooks";
import { changeController, showMenu } from "../../redux/slices/controllerSlice";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import Footer from "./Footer";

export default function Menu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ x: 200 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col justify-between bg-menuBg h-full rounded-lg"
    >
      <div>
        <Button
          onClick={() => {
            dispatch(showMenu(false));
          }}
          label="diego cerda"
          icon={true}
          title={true}
        />
        <Button
          label="_hello"
          onClick={() => {
            dispatch(showMenu(false));
            if (window.location.pathname !== "/") navigate("/");
            dispatch(changeController("home"));
            dispatch(showMenu(false));
          }}
        />
        <Button
          label="_about-me"
          onClick={() => {
            if (window.location.pathname !== "/") navigate("/");
            dispatch(changeController("about"));
            dispatch(showMenu(false));
          }}
        />
        <Button
          label="_projects"
          onClick={() => {
            if (window.location.pathname !== "/") navigate("/");
            dispatch(changeController("projects"));
            dispatch(showMenu(false));
          }}
        />
        <Button
          label="_contact-me"
          onClick={() => {
            if (window.location.pathname !== "/") navigate("/");
            dispatch(changeController("contact"));
            dispatch(showMenu(false));
          }}
        />
      </div>
      <Footer />
    </motion.div>
  );
}
