import React, { useEffect, useState } from "react";
import { svgs } from "../../../utils/svgs";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { changeAboutTab } from "../../../redux/slices/aboutSlice";

export default function ProfessionalInfo() {
  const { item, folder, arrow, arrowSmall } = svgs();
  const [education, setEducation] = useState(false);
  const [skills, setSkills] = useState(false);
  const [experience, setExperience] = useState(true);
  const [active, setActive] = useState(true);
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector((state) => state.about.tab);

  const tabs = [
    {
      onClick: () => setExperience(!experience),
      state: experience,
      folder: "#ED3109",
      label: "experience",
      items: ["summary", "professional", "frontend", "backend"],
    },
    {
      onClick: () => setSkills(!skills),
      state: skills,
      folder: "#43D9AD",
      label: "skills",
      items: ["developer"],
    },
    {
      onClick: () => setEducation(!education),
      state: education,
      folder: "#3A49A4",
      label: "education",
      items: ["university"],
    },
  ];

  useEffect(() => {
    if (window.innerWidth < 768) setActive(false);
  }, []);

  return (
    <>
      {/* title */}
      <div
        className={`flex items-center py-2.5 border-genBorder ${
          active && "border-b"
        } bg-genBorder mb-1 md:mb-0 md:bg-menuBg`}
      >
        <button onClick={() => setActive(!active)} className="pl-3.5 pr-2.5">
          {arrowSmall(active)}
        </button>
        <button
          onClick={() => setActive(!active)}
          className={`text-whiteText md:text-${
            !active && "graytext"
          } text-sm md:text-base`}
        >
          professional-info
        </button>
      </div>

      {/* folders */}
      {active && (
        <div className="pt-2 md:pt-4 md:mb-4">
          {tabs.map((tab, index) => {
            return (
              <div key={index} className="flex pb-2 text-sm md:text-base">
                <div className="flex items-start pt-1">
                  <button onClick={tab.onClick}>{arrow(tab.state)}</button>
                  {folder(tab.folder)}
                </div>
                <div className="flex flex-col text-graytext">
                  <button onClick={tab.onClick} className="flex">
                    <p
                      className={`text-${tab.state ? "whiteText" : "graytext"}`}
                    >
                      {tab.label}
                    </p>
                  </button>
                  {tab.state && (
                    <div className="pt-1">
                      {tab.items.map((i, index) => {
                        return (
                          <button
                            key={index}
                            className={`pb-1 flex items-center ${
                              currentTab === i && "text-whiteText"
                            }`}
                            onClick={() => {
                              dispatch(changeAboutTab(i));
                            }}
                          >
                            {item()}
                            {i}
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
