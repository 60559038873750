import React, { useEffect } from "react";
import { lineCodes } from "../../../utils/constants";

export default function Summary() {
  useEffect(() => {
    const div1 = document.getElementById("messages");
    const div2 = document.getElementById("numbers");

    if (div1 && div2) {
      // Agregar un evento de desplazamiento al div1
      div1.addEventListener("scroll", () => {
        // Sincronizar el desplazamiento del div2 con el div1
        div2.scrollTop = div1.scrollTop;
      });
    }
  }, []);

  return (
    <>
      {/* Summary */}
      <div
        id="numbers"
        className="hidden text-graytext px-9 pt-2 overflow-y-hidden md:block"
      >
        {lineCodes.map((e, i) => {
          return <p key={i}>{e}</p>;
        })}
      </div>
      <div className="px-6 py-4 text-whiteText md:hidden">
        <span>{`// professional-info `}</span>
        <span className="text-graytext"> / summary</span>
      </div>
      <div
        id="messages"
        className="pt-2 text-graytext break-word w-full px-8 overflow-y-scroll md:w-2/5 md:px-0"
      >
        <p className="hidden md:block">{`/**`}</p>
        <br />
        <p>
          I have about 2 years of professional experience as a Full Stack
          developer, with a major focus on backend development.
        </p>
        <br />
        <p>
          My experience lies in backend development, where I have participated
          in the creation of robust APIs and backend systems, using languages
          such as Javascript with NodeJS and also Python for personal projects.
        </p>
        <br />
        <p>
          I have experience with several databases, including PostgreSQL,
          MongoDB, Firebase and SQLite, allowing me to ensure optimal
          performance and reliability.
        </p>
        <br />
        <p>
          Additionally, my skills extend to use frontend frameworks such as
          React, React-Native, NextJS where I have developed some projects to
          deliver exceptional user experiences to the user.
        </p>
        <br />
        <p>
          In my current role as a Full Stack developer, I have the opportunity
          to apply my diversified skill set to create applications and systems
          that improve operational efficiency and deliver exceptional user
          experiences. I was one of the first members of the team, where I have
          played an important role in contributing within all stages of product
          development.
        </p>
        <br />
        <p>
          Overall, I am a versatile and reliable professional, continuously
          seeking new challenges and opportunities to contribute my skills and
          deliver exceptional results.
        </p>
        <br />
        <br />
        <br />
        <p className="hidden md:block">&nbsp;*/</p>
      </div>
    </>
  );
}
