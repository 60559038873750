import React from "react";
import NotFoundLogo from "../../assets/Animation404.gif";

export default function NoProjects() {
  return (
    <div className="flex flex-col items-center mt-12 h-full pt-14 pb-12">
      <div className="h-48 w-48 rounded-full bg-[#FFFFFF] flex justify-center items-center overflow-hidden mb-8">
        <img src={NotFoundLogo} alt="" />
      </div>
      <div className="flex flex-col items-center text-graytext">
        <p className="mb-4">
          Dang! Maybe the project I did with this stack was for my current job
          and I can't share it.
        </p>
        <p className="mb-4">OR</p>
        <p className="mb-4">
          I learned the tech stack and didn't make a presentable project with it
          yet
        </p>
      </div>
    </div>
  );
}
