import React from "react";
import { svgs } from "../utils/svgs";
import { motion } from "framer-motion";
import Container from "../components/Snake/Container";
import { useAppSelector } from "../redux/hooks";

export default function Home() {
  const { homeBlur } = svgs();
  const { menu } = useAppSelector((state) => state.controller);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 1 }}
      className={`flex h-full relative ${menu && "hidden"}`}
    >
      <div className="flex flex-col justify-center items-center w-full md:w-3/5">
        <div className="mx-10">
          <div>
            <h1 className="text-lg text-gtext">Hi all. I am</h1>
            <h1 className="text-6xl text-gtext md:text-7xl">Diego Cerda</h1>
            <h1 className="text-purptext text-xl md:text-3xl">{`> Full Stack Developer`}</h1>
          </div>
          <div className="mt-32">
            <h1 className="hidden text-graytext md:block">{`// complete the game to continue`}</h1>
            <h1 className="hidden text-graytext md:block">
              {`// you can also see it on my Github page`}
            </h1>
            <h1 className="text-graytext text-sm md:hidden">{`// find my profile on Github: `}</h1>
            <span className="text-sm text-purptext">const </span>
            <span className="text-sm text-esmeraldtext"> githubLink </span>
            <span className="text-sm text-esmeraldtext"> = </span>
            <span className="text-sm text-orangetext">"</span>
            <span className="text-sm text-orangetext underline underline-offset-1">
              https://github.com/Xxmbxxmb
            </span>
            <span className="text-sm text-orangetext">"</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="absolute right-0 bottom-0">{homeBlur("blue")}</div>
        <div className="absolute top-0 right-0">{homeBlur("green")}</div>
        <div className="justify-center items-center hidden xl:flex">
          <Container />
        </div>
      </div>
    </motion.div>
  );
}
