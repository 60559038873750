import { useEffect, useRef, useState } from "react";
import {
  CANVAS_SIZE,
  SNAKE_START,
  APPLE_START,
  SCALE,
  SPEED,
  DIRECTIONS,
} from "./constants";
import { useInterval } from "./useInterval";
import { useAppDispatch } from "../../redux/hooks";
import { setGameState, setWinner } from "../../redux/slices/snakeSlice";

export default function useSnake({
  foodLeft,
  setFoodLeft,
}: {
  foodLeft: number[];
  setFoodLeft: (x: any) => void;
}) {
  const canvasRef: any = useRef();
  const [snake, setSnake] = useState(SNAKE_START);
  const [apple, setApple] = useState(APPLE_START);
  const [dir, setDir] = useState([0, -1]);
  const [speed, setSpeed] = useState<number | null>(null);
  const [gameOver, setGameOver] = useState(false);
  const [playing, setPlaying] = useState(false);
  const dispatch = useAppDispatch();

  useInterval(() => gameLoop(), speed);

  const endGame = () => {
    setSpeed(null);
    setGameOver(true);
    dispatch(setGameState(true));
    setPlaying(false);
  };

  const moveSnake = ({ keyCode }: { keyCode: 37 | 38 | 39 | 40 }) =>
    keyCode >= 37 && keyCode <= 40 && setDir(DIRECTIONS[keyCode]);

  const createApple = () =>
    apple.map((_a, i) => Math.floor(Math.random() * (CANVAS_SIZE[i] / SCALE)));

  const checkCollision = (piece: any, snk = snake) => {
    if (
      piece[0] * SCALE >= CANVAS_SIZE[0] ||
      piece[0] < 0 ||
      piece[1] * SCALE >= CANVAS_SIZE[1] ||
      piece[1] < 0
    )
      return true;

    for (const segment of snk) {
      if (piece[0] === segment[0] && piece[1] === segment[1]) return true;
    }
    return false;
  };

  const checkAppleCollision = (newSnake: any) => {
    if (newSnake[0][0] === apple[0] && newSnake[0][1] === apple[1]) {
      let newApple = createApple();
      while (checkCollision(newApple, newSnake)) {
        newApple = createApple();
      }
      setApple(newApple);
      setFoodLeft(foodLeft.slice(0, -1));
      return true;
    }
    return false;
  };

  const gameLoop = () => {
    const snakeCopy = JSON.parse(JSON.stringify(snake));
    const newSnakeHead = [snakeCopy[0][0] + dir[0], snakeCopy[0][1] + dir[1]];
    snakeCopy.unshift(newSnakeHead);
    if (checkCollision(newSnakeHead)) endGame();
    if (!checkAppleCollision(snakeCopy)) snakeCopy.pop();
    setSnake(snakeCopy);
  };

  const startGame = () => {
    setFoodLeft([1, 2, 3, 4, 5]);
    dispatch(setWinner(false));
    setSnake(SNAKE_START);
    setApple(APPLE_START);
    setDir([0, -1]);
    setSpeed(SPEED);
    setGameOver(false);
    dispatch(setGameState(false));
    setPlaying(true);
  };

  useEffect(() => {
    const context = canvasRef.current.getContext("2d");
    context.setTransform(SCALE, 0, 0, SCALE, 0, 0);
    context.clearRect(0, 0, window.innerWidth, window.innerHeight);
    context.fillStyle = "#43D9AD";
    snake.forEach(([x, y]) => context.fillRect(x, y, 1, 1));

    // Dibujar la manzana como un círculo
    context.fillStyle = "#43D9AD"; // Cambia el color según tus preferencias
    const appleRadius = 0.5; // Radio del círculo de la manzana
    context.beginPath();
    context.arc(apple[0] + 0.5, apple[1] + 0.5, appleRadius, 0, Math.PI * 2);
    context.fill();
    context.closePath();
  }, [snake, apple, gameOver]);

  useEffect(() => {
    if (!foodLeft.length) dispatch(setWinner(true));
  }, [foodLeft, dispatch]);

  return {
    startGame,
    moveSnake,
    canvasRef,
    playing,
  };
}
